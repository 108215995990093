import React from "react";
import styles from "./styles.module.scss";
import { StaffCard, TextCluster, StaffCardProps } from "components";
import {
	ChinazaSmart,
	JojoSakibu,
	JolomiDavid,
	KelvinOfili,
	PaulOsinowo,
	TreasureOladunni,
	EduErondu,
} from "assets";

const SectionThree = () => {
	const staffList: StaffCardProps[] = [
		{
			staffName: "Oritsejolomi David",
			staffImage: JolomiDavid,
			staffPosition: "Product manager",
			info1: "Oversees the progress of everyone on the team.",
			info2: "A genius!",
			info3: "Plays football on the weekends",
		},
		{
			staffName: "Paul Osinowo",
			staffImage: PaulOsinowo,
			staffPosition: "Engineer",
			info1: "Lead Engineer.",
			info2: "Engineering Maestro, builds everything from scratch.",
			info3: "Cool, calm but fiesty!",
		},
		{
			staffName: "Kelvin Ofili",
			staffImage: KelvinOfili,
			staffPosition: "Engineer",
			info1: "Our Frontend Engineer.",
			info2: "Video games or nothing!",
			info3: "6'0 on a sunny day.",
		},
		{
			staffName: "Sakibu Abdul-Mojeed",
			staffImage: JojoSakibu,
			staffPosition: "product Designer",
			info1: "A very creative Product Designer.",
			info2: "His mind is his figma.",
			info3: "Really likes Caprisonne.",
		},
		{
			staffName: "Chinaza Darlington",
			staffImage: ChinazaSmart,
			staffPosition: "product Designer",
			info1: "Responsible for a huge part of our early designs.",
			info2: "He's Smart. No, literally; we call him Smart.",
			info3: "Lives in Enugu, Nigeria.",
		},
		{
			staffName: "Treasure Oladunni",
			staffImage: TreasureOladunni,
			staffPosition: "Brand Designer",
			info1: "Our Brand Designer!",
			info2: "Originality is all he knows.",
			info3: "Doesn't stop improving.",
		},
	];
	return (
		<>
			<section className={`column2 ${styles.sectionThree}`}>
				<TextCluster
					subheading={"MEET THE TEAM"}
					subDetails={
						<div className={styles.subDetails}>
							The Force Behind The
							<span className={styles.subDetails__green}>Magic</span>
						</div>
					}
					description="We're more than a team; we're a family. Our superb connection and understanding of one another sets us apart."
					descriptionWidth="60%"
				/>
				<StaffCard
					staffName="Edu Erondu"
					staffImage={EduErondu}
					staffPosition="C.E.O."
					className={styles.ceoCard}
					info1="The Boss!"
					info2="Our very own Visionary."
					info3="Eats Burger and Fries on Wednesdays."
				/>
				<div className={` ${styles.sectionThree__staffList}`}>
					{staffList.map((staff, idx) => {
						return <StaffCard {...staff} key={idx} />;
					})}
				</div>
			</section>
		</>
	);
};

export { SectionThree };
