import React from "react";
import styles from "./styles.module.scss";
import {
	Button,
	Heading,
	HeroComponent,
	Description,
	TopSection,
} from "components";
import { Routes } from "router";
import { ConsultingHero, ConsultingHeroBackground } from "assets";
import { SectionOne } from "./sectionOne";
import { SectionTwo } from "./sectionTwo";
import { SectionThree } from "./sectionThree";
import { SectionFour } from "./sectionFour";

const ConsultingUI = () => {
	const recipientEmail = "info@guidedhospitality.com";

	const redirectToLink = () => {
		window.location.href = `mailto:${recipientEmail}`;
	};
	return (
		<>
			<TopSection
				greenText
				redirectLink={Routes.consulting}
				text="Consultation"
			/>
			<div className={styles.consulting}>
				<HeroComponent
					heading={
						<Heading textAlign className={styles.consulting__heroHeader}>
							<div className={styles.consulting__heroHeader__discover}>
								Discover: <span>Halatech</span>
							</div>
							<div className={styles.consulting__heroHeader__service}>
								Consulting Service
							</div>
						</Heading>
					}
					description={
						<Description
							paragraph="Elevate your communication ecosystem with Twilio Flex, maximizing AI capabilities for intelligent automation and personalized experiences, leveraging its power for a responsive, scalable approach tailored to your evolving business needs"
							width="40%"
						/>
					}
					button={
						<Button
							onClick={redirectToLink}
							className={styles.consulting__heroBtn}
							type="fill"
						>
							Contact us
						</Button>
					}
					image={
						<div className={`row ${styles.consulting__heroImage}`}>
							<img
								src={ConsultingHeroBackground}
								alt="Not Found"
								className={styles.consulting__heroImage__background}
								loading="lazy"
							/>
							<img
								src={ConsultingHero}
								alt="Not Found"
								className={styles.consulting__heroImage__hero}
								loading="lazy"
							/>
						</div>
					}
				/>
				<SectionOne />
				<SectionTwo />
				<SectionThree />
				<SectionFour />
			</div>
		</>
	);
};

export { ConsultingUI };
