import React, { useState } from "react";
import styles from "./styles.module.scss";

export interface StaffCardProps {
	staffName: string;
	staffImage: string;
	staffPosition: string;
	info1?: string;
	info2?: string;
	info3?: string;
	className?: string;
}

const StaffCard: React.FC<StaffCardProps> = ({
	staffName,
	staffImage,
	staffPosition,
	info1,
	info2,
	info3,
	className,
}) => {
	const [isPopupVisible, setPopupVisibility] = useState(false);

	return (
		<div
			className={`${className} ${styles.staff}`}
			onMouseEnter={() => setPopupVisibility(true)}
			onMouseLeave={() => setPopupVisibility(false)}
		>
			<div className={`column ${styles.staff__parent}`}>
				<img
					src={staffImage}
					alt={staffName || staffPosition || "Staff Member"}
					loading="lazy"
					className={styles.staff__parent__staffImage}
				/>
				<p className={styles.staff__parent__staffName}>{staffName}</p>
				<p className={styles.staff__parent__staffPosition}>{staffPosition}</p>
			</div>

			{isPopupVisible && (
				<div className={styles.popup}>
					<ul>
						<li> {info1}</li>
						<li> {info2}</li>
						<li> {info3}</li>
					</ul>
				</div>
			)}
		</div>
	);
};

export { StaffCard };
