import React from "react";
import { ConfirmationUI } from "features";

const Confirmation = () => {
	return (
		<div>
			<ConfirmationUI />
		</div>
	);
};

export { Confirmation };
