import { logPageView } from "analytics";
import React, { useEffect } from "react";
import { MainRouter } from "router";
import ReactGA from "react-ga";

const App = () => {
	const gaEnv = process.env.gs;
	useEffect(() => {
		if (gaEnv) {
			ReactGA.initialize(gaEnv);
		}
		// Make the GA-Tracking-ID secret. Put in an .env
		logPageView();
	}, []);
	return <MainRouter />;
};

export default App;
