import React, { useState } from "react";
import styles from "./styles.module.scss";
import { FooterLogo } from "assets";
import { Button, Metric } from "components";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
	const [email, setEmail] = useState("");
	const navigate = useNavigate();
	return (
		<>
			<div className={styles.footer}>
				<div className={`${styles.footer__top} row2`}>
					<div className={`column0 ${styles.footer__top__left}`}>
						<div className={`row0 ${styles.footer__top__left__logo}`}>
							<img
								src={FooterLogo}
								alt="Not Found"
								className={styles.footer__top__left__logo__vector}
							/>

							<p>
								Our proven AI-powered platform ensures an exceptional customer
								experience, making every interaction fulfilling and valuable for
								your clients.
							</p>
						</div>
						<div className={`row0 ${styles.footer__top__left__cookies}`}>
							<ul className={`row0 ${styles.footer__top__left__cookies__list}`}>
								<li>Cookies</li>
								<li>Privacies</li>
								<li>Terms</li>
							</ul>
						</div>
					</div>
					<div className={styles.footer__top__right}>
						<p className={styles.footer__top__right__paragraph}>
							Experience <span>Halatech</span>ConversationalAI®, Join our
							waitlist!
						</p>
						<Metric color={"#f0f0f0"} />
						<div
							className={`column0 ${styles.footer__top__right__getAccessEmail}`}
						>
							<input
								type="email"
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								value={email}
								placeholder="Enter Your Business Email Address"
							/>
							<Button
								onClick={() => {
									console.log(email);
									navigate("/confirmation");
								}}
								className={styles.btn}
								type="fill"
							>
								Get Early Access
							</Button>
						</div>
					</div>
				</div>
				<div className={`${styles.footer__rightsReserved} row`}>
					<p>© 2023 HalaTechAI. All rights reserved </p>
				</div>
			</div>
		</>
	);
};

export { Footer };
