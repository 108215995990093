import React from "react";
import styles from "./styles.module.scss";
import { OurServicesCard, OurServicesCardProps, TextCluster } from "components";
import {
	OurConsultingService1,
	OurConsultingService2,
	OurConsultingService3,
} from "assets";

const SectionTwo = () => {
	const bottomCards: OurServicesCardProps[] = [
		{
			serviceImage: OurConsultingService2,
			serviceHeader: "Twilio Serverless Infrastructure",
			serviceDescription:
				"Our experts efficiently set up and manage Twilio's serverless infrastructure, optimizing performance and cost-effectiveness. Leverage Twilio's full serverless potential through our skilled implementation.",
		},
		{
			serviceImage: OurConsultingService3,
			serviceHeader: "Task Router Configuration",
			serviceDescription:
				"Streamlined task routing boosts agent productivity and ensures excellent customer service. Our consultants collaborate closely with your team, tailoring Twilio's Task Router system to match your business processes.",
		},
	];
	return (
		<>
			<section className={`column2 ${styles.sectionTwo}`}>
				<div className={`row2 ${styles.sectionTwo__top}`}>
					<TextCluster
						subheading={"OUR SERVICES"}
						buttonType={"fill"}
						buttonText={"Contact us"}
						description={
							"At Halatech, we help streamline your Twilio Flex development team. With our staff augmentation services, our Twilio experts seamlessly integrate to offer essential support. Services include:"
						}
						subDetails={
							<div className={styles.subDetails}>
								Our
								<span className={styles.subDetails__green}>Consulting</span>
								Service
							</div>
						}
						descriptionWidth={"100%"}
						className={styles.sectionTwo__top__textCluster}
						link
						redirectLink={"mailto:info@guidedhospitality.com"}
					/>
					<OurServicesCard
						serviceImage={OurConsultingService1}
						serviceHeader={"Twilio Flex Plugin Development"}
						serviceDescription={
							"Our skilled developers craft tailored Twilio Flex plugins to meet your specific needs, delivering high-quality development services for additional features, integrations, or enhancements."
						}
						className={styles.sectionTwo__top__serviceCard}
					/>
				</div>

				<div className={`row2 ${styles.sectionTwo__bottom}`}>
					{bottomCards.map((card, idx) => (
						<OurServicesCard {...card} key={idx} />
					))}
				</div>
			</section>
		</>
	);
};

export { SectionTwo };
