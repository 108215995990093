/**
 * ROUTES
 *
 * ===============================================
 *
 * This object depicts the component url structure.
 * It contains a key-value pair of components and their respective URLs
 *
 */

export const Routes = {
	// General
	home: "/",
	consulting: "/consulting",
	confirmation: "/confirmation",
	staff: "/staff",
};
