import React from "react";
import styles from "./styles.module.scss";
import { Button, Description, SubHeading } from "components";

interface TextClusterProps {
	subheading: string;
	buttonType?: "fill" | "transparent";
	buttonText?: string;
	description?: string;
	textClusterImage?: string;
	subDetails: React.ReactNode;
	className?: string;
	descriptionWidth?: string;
	onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	link?: any;
	redirectLink?: any;
}
const TextCluster: React.FC<TextClusterProps> = ({
	subheading,
	buttonType,
	buttonText,
	description,
	textClusterImage,
	subDetails,
	descriptionWidth,
	className,
	link,
	redirectLink,
	onClick,
}) => {
	return (
		<>
			<div className={`column2 ${styles.textCluster} ${className}`}>
				<div className={styles.textCluster__subheading}>
					<SubHeading subheading={subheading} />
				</div>
				{subDetails}
				{description ? (
					<div className={`row ${styles.textCluster__description}`}>
						<Description
							paragraph={description}
							width={descriptionWidth ? descriptionWidth : "45%"}
						/>
					</div>
				) : (
					""
				)}
				{link ? (
					<div className={styles.textCluster__btn}>
						<a href={redirectLink}>
							<Button type={buttonType ? buttonType : "fill"} onClick={onClick}>
								{buttonText}
							</Button>
						</a>
					</div>
				) : (
					<div className={styles.textCluster__btn}>
						{buttonText ? (
							<Button type={buttonType ? buttonType : "fill"} onClick={onClick}>
								{buttonText}
							</Button>
						) : (
							""
						)}
					</div>
				)}
				{textClusterImage && (
					<img
						src={textClusterImage}
						loading="lazy"
						alt="Not Found"
						className={styles.textCluster__image}
					/>
				)}
			</div>
		</>
	);
};

export { TextCluster };
