import React from "react";
import styles from "./styles.module.scss";
import { Description } from "components";
export interface OurServicesCardProps {
	serviceImage: string;
	serviceHeader: string;
	serviceDescription: string;
	className?: string;
}
const OurServicesCard: React.FC<OurServicesCardProps> = ({
	serviceImage,
	serviceHeader,
	serviceDescription,
	className,
}) => {
	return (
		<>
			<div className={`row ${styles.serviceCard} ${className}`}>
				<div className={styles.serviceCard__containerDiv}>
					<img
						src={serviceImage}
						alt="Not Found"
						loading="lazy"
						className={styles.serviceCard__containerDiv__image}
					/>
					<div className={styles.serviceCard__containerDiv__text}>
						<p className={styles.serviceCard__containerDiv__text__heading}>
							{serviceHeader}
						</p>
						<Description
							paragraph={serviceDescription}
							width={"100%"}
							className={styles.serviceCard__containerDiv__text__description}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export { OurServicesCard };
