import React from "react";
import styles from "./styles.module.scss";
import {
	WhyChooseHTACard,
	WhyChooseHTACardProps,
	TextCluster,
} from "components";
import {
	IndustryExpertise,
	ContinuousSupport,
	SeamlessIntegration,
	ScalabilityFlexibility,
	TailoredSolutions,
} from "assets";

const SectionThree = () => {
	const bottomCards: WhyChooseHTACardProps[] = [
		{
			whyChooseVector: <SeamlessIntegration />,
			whyChooseHeader: "Seamless Integration",
			whyChooseDescription:
				"Tech ecosystems can be complex. That's Why we focus on seamless integration, ensuring that our consulting solutions fit perfectly with your existing systems and processes.",
		},
		{
			whyChooseVector: <ScalabilityFlexibility />,
			whyChooseHeader: "Scalability / Flexibility",
			whyChooseDescription:
				"As your business evolves, so do your needs. We design our consulting services to be scalable and flexible, adapting to your changing requirements.",
		},
		{
			whyChooseVector: <ContinuousSupport />,
			whyChooseHeader: "Continuous Support",
			whyChooseDescription:
				"Our commitment extends beyond project completion. We provide support to ensure the solutions we implement not only meet but exceed your evolving business needs.",
		},
	];
	return (
		<>
			<section className={`column2 ${styles.sectionThree}`}>
				<div className={`row2 ${styles.sectionThree__top}`}>
					<TextCluster
						subheading={"WHY CHOOSE US"}
						subDetails={
							<div className={`${styles.subDetails} column0`}>
								<div>
									Why{" "}
									<span className={styles.subDetails__green}>Halatech AI</span>
								</div>
								<div>Consulting service?</div>
							</div>
						}
						descriptionWidth={"100%"}
						className={styles.sectionThree__top__textCluster}
					/>
					<WhyChooseHTACard
						whyChooseVector={<IndustryExpertise />}
						whyChooseHeader={"Industry Expertise"}
						whyChooseDescription={
							"Our team of consultants brings deep industry knowledge and expertise in Twilio/Twilio Flex CPaaS. We stay updated with the latest trends and best practices."
						}
						className={styles.sectionThree__top__whyChooseCard}
					/>
					<WhyChooseHTACard
						whyChooseVector={<TailoredSolutions />}
						whyChooseHeader={"Tailored Solutions"}
						whyChooseDescription={
							"We don’t offer cookie-cutter solutions. We dig deep to understand your unique challenges and tailor our services to meet them, maximizing your ROI."
						}
						className={styles.sectionThree__top__whyChooseCard}
					/>
				</div>

				<div className={`row2 ${styles.sectionThree__bottom}`}>
					{bottomCards.map((card, idx) => (
						<WhyChooseHTACard {...card} key={idx}/>
					))}
				</div>
			</section>
		</>
	);
};

export { SectionThree };
