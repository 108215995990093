import React from "react";
import { TopSection } from "components";
import styles from "./styles.module.scss";

export interface BaseLayoutProps {
	children: any;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
	return (
		<>
			<TopSection greenText={false} />
			<main className={styles.main}>{children}</main>
		</>
	);
};

export { BaseLayout };
