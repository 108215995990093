import React from "react";
import { ConsultingUI } from "features";

const Consulting = () => {
	return (
		<div>
			<ConsultingUI />
		</div>
	);
};

export { Consulting };
