import React from "react";
import styles from "./styles.module.scss";

interface DescriptionProps {
	paragraph: string;
	width: string;
	className?: string;
}

const Description: React.FC<DescriptionProps> = ({
	paragraph,
	width,
	className,
}) => {
	return (
		<>
			<p
				className={`${className} ${styles.description}`}
				style={{ width: width }}
			>
				{paragraph}
			</p>
		</>
	);
};

export { Description };
