import React from "react";
import styles from "./styles.module.scss";
import {
	KeyHighlightsCard,
	KeyHighlightsCardProps,
	TextCluster,
} from "components";
import { BeganOperations, PartnersSize, TeamSize, UsersNumber } from "assets";

const SectionTwo = () => {
	const keyHightlights: KeyHighlightsCardProps[] = [
		{
			title: "Began Operations",
			vector: <BeganOperations />,
			metric: "2023",
		},
		{
			title: "No. of Users",
			vector: <UsersNumber />,
			metric: "200+",
		},
		{
			title: "Team Size",
			vector: <TeamSize />,
			metric: "8",
		},
		{
			title: "Partners",
			vector: <PartnersSize />,
			metric: "4+",
		},
	];
	return (
		<>
			<section className={styles.sectionTwo}>
				<TextCluster
					subheading={"ABOUT US"}
					subDetails={
						<div className={styles.subDetails}>
							Key
							<span className={styles.subDetails__green}>Highlights</span>
						</div>
					}
					className={styles.sectionTwo__textCluster}
				/>
				<div className={` ${styles.sectionTwo__keyHighlights}`}>
					{keyHightlights.map((highlight, idx) => (
						<KeyHighlightsCard {...highlight} key={idx} />
					))}
				</div>
			</section>
		</>
	);
};

export { SectionTwo };
