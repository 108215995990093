import React from 'react'
import { HomeUI } from '../../features'

const Home = () => {
  return (
    <div>
      <HomeUI />
    </div>
  )
}

export {Home}
