import React from "react";
import styles from "./styles.module.scss";

interface ButtonProps {
	children?: string;
	className?: string;
	onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	type: "fill" | "transparent";
}
const Button: React.FC<ButtonProps> = ({
	children,
	className,
	onClick,
	type,
}) => {
	return (
		<>
			<button
				onClick={onClick}
				className={`${styles.button} ${className} ${styles[type]}`}
			>
				{children}
			</button>
		</>
	);
};

export { Button };
