import React from "react";
import styles from "./styles.module.scss";
import {
	TopSection,
	GradientText,
	HeroComponent,
	Heading,
	Description,
} from "components";
import { Routes } from "router";
import { StaffHero } from "assets";
import { SectionOne } from "./sectionOne";
import SectionFour from "./sectionFour";
import { SectionThree } from "./sectionThree";
import { SectionTwo } from "./sectionTwo";
import { SectionFive } from "./sectionFive";

const StaffUI = () => {
	return (
		<>
			<TopSection greenText redirectLink={Routes.staff} text="View Staff" />

			<div className={`column2 ${styles.staff}`}>
				<GradientText text={"Our Team at"} />
				<HeroComponent
					heading={
						<Heading textAlign className={styles.staff__heroHeader}>
							<div className={styles.staff__heroHeader__scenes}>
								Behind the Scenes:
							</div>
							<div className={styles.staff__heroHeader__team}>Our Team</div>
						</Heading>
					}
					description={
						<Description
							paragraph="	At homebase, our strength lies in unity. We're more than a SaaS
						team; we're a force bound by passion and purpose."
							width="45%"
						/>
					}
					image={
						<img src={StaffHero} alt="" className={styles.staff__heroImage} />
					}
				/>
				<SectionOne />
				<SectionTwo />
				<SectionThree />
				<SectionFour />
				<SectionFive />
			</div>
		</>
	);
};

export { StaffUI };
