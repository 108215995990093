import { PartnerWithUs } from "assets";
import { TextCluster } from "components";
import React from "react";
import styles from "./styles.module.scss";

const SectionFour = () => {
	return (
		<>
			<section className={styles.sectionFour}>
				<TextCluster
					description="Partner with Halatech for deep Twilio/Twilio Flex CPaaS expertise. Our consultants closely collaborate with your team to understand needs, assess infrastructure, and offer tailored solutions aligned with your business goals. With a collaborative approach, our consulting seamlessly integrates, delivering measurable results."
					descriptionWidth="50%"
					subheading={"partnership"}
					subDetails={
						<div className={styles.subDetails}>
							Partner With <span className={styles.subDetails__green}>Us</span>
						</div>
					}
					buttonType={"fill"}
					buttonText={"Contact us"}
					textClusterImage={PartnerWithUs}
					redirectLink={"mailto:info@guidedhospitality.com"}
					link
				/>
				<TextCluster
					description="We provide comprehensive support throughout your AI journey. Contact us today to unlock the power of AI and revolutionize your business."
					descriptionWidth="60%"
					subheading={"get started with us"}
					subDetails={
						<div className={styles.subDetails}>
							Discover the Power of AI with{" "}
							<span className={styles.subDetails__green}>Halatech AI</span>
						</div>
					}
					buttonType={"fill"}
					buttonText={"Contact us"}
					redirectLink={"mailto:info@guidedhospitality.com"}
					link
				/>
			</section>
		</>
	);
};

export { SectionFour };
