import React from "react";
import styles from "./styles.module.scss";
import { ConfirmationGradient, BackgroundGrid } from "assets";
import { Heading, Button, Description, FooterText } from "components";
import { useNavigate } from "react-router-dom";
const ConfirmationUI = () => {
	const navigate = useNavigate();
	return (
		<>
			<div className={`column2 ${styles.confirmation}`}>
				<img
					src={BackgroundGrid}
					alt="Not Found"
					loading="lazy"
					className={styles.socials}
				/>
				<div className={`row2 ${styles.confirmation__top}`}>
					<div className={`column2 ${styles.confirmation__top__middleText}`}>
						<Heading
							textAlign
							className={styles.confirmation__top__middleText__heading}
						>
							<span
								className={
									styles.confirmation__top__middleText__heading__lightgrey
								}
							>
								Thank You for Joining
							</span>
							<div>
								<span
									className={
										styles.confirmation__top__middleText__heading__halatech
									}
								>
									Halatech
								</span>
								<span
									className={
										styles.confirmation__top__middleText__heading__darkgrey
									}
								>
									ConversationalAI®
								</span>
							</div>
							<div
								className={
									styles.confirmation__top__middleText__heading__waitlist
								}
							>
								Waitlist! 🚀
							</div>
						</Heading>
						<Description
							paragraph="You're officially on the list! 🎉 Get ready for a first class access to the HalatechConversationalAI®. We're working hard to make it amazing, just for you."
							width="65%"
						/>
						<Button
							type={"transparent"}
							onClick={() => navigate("/consulting")}
							className={styles.btn}
						>
							check out our consulting service
						</Button>
					</div>
				</div>

				<div className={`column ${styles.confirmation__bottom}`}>
					<img src={ConfirmationGradient} alt="Not Found" loading="lazy" />
					<div className={styles.confirmation__bottom__footerText}>
						<FooterText
							className={styles.confirmation__bottom__footerText__text}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export { ConfirmationUI };
