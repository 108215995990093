import React from "react";
import styles from "./styles.module.scss";
import { TextCluster } from "components";

const SectionFive = () => {
	return (
		<>
			<section className={`column ${styles.sectionFive}`}>
				<TextCluster
					subheading={"GET STARTED WITH US"}
					subDetails={
						<div className={styles.subDetails}>
							Discover the Power of AI with
							<span className={styles.subDetails__green}>Halatech AI</span>
						</div>
					}
					description="When it comes to exceptional service, you can rely on our services to make processes easier for you.
Join us in as we evolve bigger and better. Together, let's redefine business technology.

"
					descriptionWidth="30%"
				/>
				<p className={styles.sectionFive__description}>
					Thank you for being part of <span>Halatech AI.</span> 💚
				</p>
			</section>
		</>
	);
};

export { SectionFive };
