import React from "react";
import { Footer } from "components";
import styles from "./styles.module.scss";

export interface BaseLayoutGreenProps {
	children: any;
}

const BaseLayoutGreen: React.FC<BaseLayoutGreenProps> = ({ children }) => {
	return (
		<>
			<main className={styles.main}>{children}</main>
			<Footer />
		</>
	);
};

export { BaseLayoutGreen };
