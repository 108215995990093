import React from "react";
import styles from "./styles.module.scss";
import { OverlappingBrands } from "components";

interface MetricProps {
	color?: string;
	textAlign?: boolean;
}
const Metric: React.FC<MetricProps> = ({ color, textAlign }) => {
	return (
		<>
			<div className={`row2 ${styles.metric}`}>
				<OverlappingBrands />
				<p
					className={styles.metric__paragraph}
					style={
						textAlign ? { color: color, textAlign: "center" } : { color: color }
					}
				>
					Join other businesses on the waitlist
				</p>
			</div>
		</>
	);
};

export { Metric };
