import React from "react";
import styles from "./styles.module.scss";
import {
	StaffCollage1,
	StaffCollage2,
	StaffCollage3,
	StaffCollage4,
} from "assets";

const SectionFour = () => {
	const staffCollage = [
		StaffCollage1,
		StaffCollage2,
		StaffCollage3,
		StaffCollage4,
	];
	return (
		<>
			<section className={`row ${styles.sectionFour}`}>
				{staffCollage.map((collage) => (
					<div>
						<img src={collage} alt="" />
					</div>
				))}
			</section>
		</>
	);
};

export default SectionFour;
