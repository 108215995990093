import React from "react";
import styles from "./styles.module.scss";
import { SatinRides, GuidedHospitality, HalaTech } from "assets";

const OverlappingBrands = () => {
	return (
		<>
			<div className={`row ${styles.overlappingBrands}`}>
				<div className={`row ${styles.brands}`}>
					<img
						src={HalaTech}
						alt="Not Found"
						className={styles.brands__halatech}
					/>
					<img
						src={SatinRides}
						alt="Not Found"
						className={styles.brands__satinRides}
					/>
					<img
						src={GuidedHospitality}
						alt="Not Found"
						className={styles.brands__guidedHospitality}
					/>
				</div>
				<div className={`row ${styles.circularPlus}`}>+</div>
			</div>
		</>
	);
};

export { OverlappingBrands };
