import React from "react";
import styles from "./styles.module.scss";
import { BoxOpen, Logo, RedirectArrow } from "assets";
import { Link } from "react-router-dom";
import { Routes } from "router";

interface TopSectionProps {
	greenText?: boolean;
	text?: string;
	redirectLink?: any;
}

const TopSection: React.FC<TopSectionProps> = ({
	redirectLink,
	greenText,
	text,
}) => {
	return (
		<>
			<div className={`row2 ${styles.topSection}`}>
				<Link to={Routes.home}>
					<img src={Logo} alt="" />
				</Link>
				{greenText ? (
					<div className={`row2 ${styles.redirect}`}>
						<Link to={redirectLink}>{text}</Link>
						<BoxOpen />
					</div>
				) : (
					<div className={`row2 ${styles.consulting}`}>
						<Link to={Routes.consulting}>Consultation</Link>
						<RedirectArrow />
					</div>
				)}
			</div>
		</>
	);
};

export { TopSection };
