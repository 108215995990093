import React from "react";
// import { Staf } from "features";
import { StaffUI } from "features";

const Staff = () => {
	return (
		<div>
			<StaffUI />
		</div>
	);
};

export { Staff };
