import React from "react";
import styles from "./styles.module.scss";
import { SubHeading } from "components";
import { OurMission1, OurMission2 } from "assets";

const SectionOne: React.FC = () => {
	return (
		<>
			<section className={`row2 ${styles.sectionOne}`}>
				<div className={`column0 ${styles.sectionOne__left}`}>
					<SubHeading subheading="our mission" />
					<p className={styles.sectionOne__left__paragraph}>
						Together, we're on a mission to making <b>communication</b> between
						<b> businesses</b> and <b>customers,</b> <span>easy.</span>{" "}
					</p>
				</div>
				<div className={`row0 ${styles.sectionOne__right}`}>
					<img src={OurMission1} alt="Not Found" loading="lazy" />
					<img src={OurMission2} alt="Not Found" loading="lazy" />
				</div>
			</section>
		</>
	);
};

export { SectionOne };
