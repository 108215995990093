import React from "react";
import styles from "./styles.module.scss";

interface HeadingProps {
	children: React.ReactNode;
	color?: string;
	fontSize?: string;
	textAlign?: boolean;
	className?: string;
}

const Heading: React.FC<HeadingProps> = ({
	children,
	color,
	fontSize,
	textAlign,
	className,
}) => {
	return (
		<>
			<div
				className={`${className} ${styles.heading}`}
				style={{
					color: color,
					fontSize: fontSize,
					textAlign: textAlign ? "center" : "left",
				}}
			>
				{children}
			</div>
		</>
	);
};

export { Heading };
