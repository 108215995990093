import React from "react";
import styles from "./styles.module.scss";
import { TextCluster } from "components";
import { StaffHero } from "assets";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";

const SectionOne = () => {
	const navigate = useNavigate();
	return (
		<>
			<section className={styles.aboutUs}>
				<TextCluster
					description="Halatech understands today's tech challenges. Our Twilio/Twilio Flex CPaaS consulting boosts efficiency, enhances experiences, and drives competitiveness. Leveraging our expertise, we empower companies with Twilio's potential and AI solutions."
					descriptionWidth="50%"
					subheading={"about us"}
					subDetails={
						<div className={styles.subDetails}>
							About{" "}
							<span className={styles.subDetails__green}>Halatech AI</span>{" "}
							Consulting Service
						</div>
					}
					buttonType={"transparent"}
					buttonText={"view team"}
					textClusterImage={StaffHero}
					onClick={() => {
						navigate(Routes.staff);
					}}
				/>
			</section>
		</>
	);
};

export { SectionOne };
