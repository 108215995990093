import React from "react";
import styles from "./styles.module.scss";

export interface KeyHighlightsCardProps {
	title: string;
	vector: any;
	metric: string;
}

const KeyHighlightsCard: React.FC<KeyHighlightsCardProps> = ({
	title,
	vector,
	metric,
}) => {
	return (
		<>
			<div className={styles.keyHighlights}>
				<div className={`column ${styles.keyHighlights__parent}`}>
					<p className={styles.keyHighlights__parent__title}>{title}</p>
					{vector}
					<h1 className={styles.keyHighlights__parent__metric}>{metric}</h1>
				</div>
			</div>
		</>
	);
};

export { KeyHighlightsCard };
