import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { BackgroundGrid, TextBubbles } from "assets";
import {
	Heading,
	Button,
	GradientText,
	Description,
	Metric,
	FooterText,
} from "components";

const slidingTexts = ["Automate", "Engage", "Elevate"];

const HomeUI = () => {
	const textRotatorRef = useRef<HTMLDivElement | null>(null);
	const listRef = useRef<HTMLDivElement | null>(null);
	const [height, setHeight] = useState(0);
	const [index, setIndex] = useState(0);
	const [texts, setTexts] = useState([...slidingTexts]);
	const [email, setEmail] = useState({ email: "" });
	useLayoutEffect(() => {
		const getHeight = () => {
			if (textRotatorRef.current) {
				const height = textRotatorRef.current.getBoundingClientRect().height;
				setHeight(height);
			}
		};

		getHeight();

		window.addEventListener("resize", getHeight);

		return () => window.removeEventListener("resize", getHeight);
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setIndex((prevIndex) => (prevIndex + 1) % 3);
			setTexts((prev) => [...prev, ...slidingTexts]);
		}, 3000);

		return () => clearInterval(interval);
	}, []);

	const MOVE = height;
	const navigate = useNavigate();
	const postEmail = async (e: any) => {
		e.preventDefault();

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		// Check if email is empty or doesn't match the email format
		if (!email.email || !emailRegex.test(email.email)) {
			alert("Please enter a valid email address");
			return;
		}

		try {
			const response = await fetch(
				"https://develop--affectionate-bohr-f821ea.netlify.app/.netlify/functions/app/utilities/waitlist",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(email),
				}
			);

			if (!response.ok) {
				throw new Error(`Error sending post request`);
			}
			await response.json();
			navigate("/confirmation");
		} catch (error) {
			alert(error);
		}
	};

	return (
		<>
			<div className={`column2 ${styles.home}`}>
				<img
					src={BackgroundGrid}
					alt="Not Found"
					loading="lazy"
					className={styles.socials}
				/>
				<div className={`row ${styles.home__top}`}>
					<div className={`column2 ${styles.home__top__middleText}`}>
						<GradientText text="Powered by" className={styles.poweredBy} />
						<div className={styles.textRotator} ref={textRotatorRef}>
							<div
								className={styles.list}
								style={{
									transform: `translateY(calc(${index} * -${MOVE}px))`,
								}}
								ref={listRef}
							>
								{texts.map((text, idx) => (
									<div key={idx} className={styles.textContainer}>
										<span
											style={{
												color:
													idx % 3 === 0
														? "#8ac540"
														: idx % 3 === 1
														? "#5a4fcf"
														: "#ff6f61",
											}}
										>
											{text}
										</span>
									</div>
								))}
							</div>
						</div>
						<Heading
							textAlign
							className={styles.home__top__middleText__heading}
						>
							<span
								className={styles.home__top__middleText__heading__lightgrey}
							>
								Customer Engagement with
							</span>
							<div>
								<span
									className={styles.home__top__middleText__heading__halatech}
								>
									Halatech
								</span>
								<span
									className={styles.home__top__middleText__heading__darkgrey}
								>
									ConversationalAI®
								</span>
							</div>
						</Heading>
						<Description
							paragraph="Enter the communication evolution with
              Halatech ConversationalAI—where business-customer engagement
              transforms seamlessly with intelligent automation."
							width="65%"
						/>
						<Metric textAlign />
					</div>
				</div>
				<div className={`row1 ${styles.home__middle}`}>
					<input
						type="email"
						onChange={(e) => {
							setEmail({ email: e.target.value });
						}}
						value={email.email}
						placeholder="Enter Your Business Email Address"
					/>
					<Button
						type="fill"
						onClick={(e) => {
							postEmail(e);
						}}
						className={styles.btn}
					>
						Get Early Access
					</Button>
				</div>
				<div className={`row ${styles.home__bottom}`}>
					<img src={TextBubbles} alt="Not Found" loading="lazy" />
				</div>
			</div>
			<FooterText />
		</>
	);
};

export { HomeUI };
