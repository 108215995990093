import React from "react";
import styles from "./styles.module.scss";
import { Description } from "components";
export interface WhyChooseHTACardProps {
	whyChooseVector: any;
	whyChooseHeader: string;
	whyChooseDescription: string;
	className?: string;
}

const WhyChooseHTACard: React.FC<WhyChooseHTACardProps> = ({
	whyChooseVector,
	whyChooseHeader,
	whyChooseDescription,
	className,
}) => {
	return (
		<>
			<div className={`column0 ${styles.whyChooseHTACard} ${className}`}>
				<div className={styles.whyChooseHTACard__containerDiv}>
					{whyChooseVector}
					<p className={styles.whyChooseHTACard__containerDiv__heading}>
						{whyChooseHeader}
					</p>
					<Description
						paragraph={whyChooseDescription}
						width={"100%"}
						className={styles.whyChooseHTACard__containerDiv__description}
					/>
				</div>
			</div>
		</>
	);
};

export { WhyChooseHTACard };
