import React from "react";
import styles from "./styles.module.scss";

interface SubHeadingProps {
	subheading: string;
}
const SubHeading: React.FC<SubHeadingProps> = ({ subheading }) => {
	return (
		<>
			<p className={`${styles.subheading}`}>{subheading}</p>
		</>
	);
};

export { SubHeading };
